import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from '@xstate/react';
import Script from 'next/script';

import ErrorBoundary from 'components/ErrorBoundary';
import { PartialPopover } from 'components/Popover';
import ProductDetailsBlock, {
	PRODUCT_IMPORTANT_INFO_ID,
	PRODUCT_REVIEWS_ID,
	PRODUCT_TECHNICAL_ATTRIBUTES_ID,
	ProductDetailsContext,
} from 'components/ProductDetails';
import {
	useFeatureToggle,
	useGlobalMachinesContext,
	useSelectedStore,
} from 'contexts';
import {
	useEffectOnce,
	useGlobalLinks,
	useHash,
	useProductGTMEvents,
	useProductListGTMEvents,
	useProductQuestions,
	useProductStock,
	useRelatedProducts,
	useReviews,
	useSimilarProducts,
	useSitecoreContext,
	useTemporaryStoredValue,
	useValueChangeEffect,
} from 'hooks';
import { useProductPrint, useReviewImages } from 'hooks/product-details';
import { JulaComponentProps } from 'lib/component-props';
import { CampaignResponse, Product } from 'models/product';
import { selectPurchaseButtonState } from 'state-machines/cart';
import {
	selectWishlistAddedProduct,
	selectWishlistAddedProductPopoverIsOpen,
	selectWishlistAdditionalSalesProducts,
	selectWishlistButtonState,
	selectWishlistMiniMessage,
} from 'state-machines/wishlist';
import {
	GTMItemListId,
	GTMItemListName,
	pushToGTM,
} from 'utils/GoogleTagManager';
import { ignorePromiseRejection, is, sendGlobalEvent } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

type Props = JulaComponentProps & {
	/** Product data following the product model. */
	fields: Product;
};

function ProductDetails({ fields }: Props) {
	const product = fields;
	const {
		hasAccessories,
		hasBelongsTo,
		hasSpareParts,
		id,
		isCustomizable,
		productId,
		salesAttributes,
		sizeGuideUrl,
		variants,
	} = product;
	const { t } = useI18n();
	const { sitecoreContext } = useSitecoreContext();
	const { reviewPolicy } = useGlobalLinks();
	const { cartService, wishlistService } = useGlobalMachinesContext();
	const [isSizeGuidePopoverOpen, setIsSizeGuidePopoverOpen] = useState(false);
	// Stock is only available on the client.
	const [isStockActive, setIsStockActive] = useState(false);
	useEffectOnce(() => {
		setIsStockActive(true);
	});

	const [isAboutBrandOpen, setIsAboutBrandOpen] = useState(false);
	const [isSparePartsOpen, setIsSparePartsOpen] = useState(false);
	const [isBelongsToOpen, setIsBelongsToOpen] = useState(
		product.productType === 'SparePart',
	);
	const [isProductQuestionsOpen, setIsProductQuestionsOpen] = useState(false);

	const [isTechnicalAttributesOpen, setIsTechnicalAttributesOpen] =
		useState(false);
	const [isReviewsOpen, setIsReviewsOpen] = useState(false);
	const [isImportantInfoOpen, setIsImportantInfoOpen] = useState(false);
	const urlHash = useHash();
	useEffectOnce(() => {
		if (urlHash === PRODUCT_TECHNICAL_ATTRIBUTES_ID) {
			setIsTechnicalAttributesOpen(true);
		}
		if (urlHash === PRODUCT_REVIEWS_ID) {
			setIsReviewsOpen(true);
		}
		if (urlHash === PRODUCT_IMPORTANT_INFO_ID) {
			setIsImportantInfoOpen(true);
		}
	});

	const {
		hasNextPage: belongsToHasNextPage,
		isLoading: belongsToIsLoading,
		isLoadingMore: belongsToIsLoadingMore,
		items: belongsToProducts,
		loadMore: loadMoreBelongsTo,
	} = useRelatedProducts({
		variantId: id,
		relationType: 'BelongsTo',
		isActive: hasBelongsTo && isBelongsToOpen,
	});
	const {
		hasNextPage: sparePartsHasNextPage,
		isLoading: sparePartsIsLoading,
		isLoadingMore: sparePartsIsLoadingMore,
		items: sparePartsProducts,
		loadMore: loadMoreSpareParts,
	} = useRelatedProducts({
		variantId: id,
		relationType: 'SpareParts',
		isActive: hasSpareParts && isSparePartsOpen,
	});
	const {
		hasNextPage: accessoriesHasNextPage,
		isLoading: accessoriesIsLoading,
		isLoadingMore: accessoriesIsLoadingMore,
		items: accessoriesProducts,
		loadMore: loadMoreAccessories,
	} = useRelatedProducts({
		variantId: id,
		relationType: 'Accessories',
		isActive: hasAccessories,
	});

	const { isLoading: isLoadingInitialSimilarProducts, items: similarProducts } =
		useSimilarProducts({
			productId,
			pageSize: 10,
			isActive: true,
		});

	const {
		hasNextPage: productQuestionsHasNextPage,
		isLoading: isLoadingInitialQuestions,
		isLoadingMore: isLoadingMoreQuestions,
		items: productQuestions,
		loadMore: loadMoreProductQuestions,
		newQuestionsAllowed,
	} = useProductQuestions(id);

	const [creditSimulationPopoverIsOpen, setCreditSimulationPopoverIsOpen] =
		useState(false);

	const { selectedStore, setSelectedStore } = useSelectedStore();

	const {
		allStoresStock,
		currentStoreStock,
		isInitialLoadingProductStock,
		isLoadingAllStoresStock,
		isLoadingNearbyStoresStock,
		isLoadingProductStock,
		isLoadingVariantsStock,
		loadAllStores,
		loadNearbyStores,
		loadVariantsStock,
		nearbyStoresStock,
		productStock: currentProductStock,
		variantsStock,
	} = useProductStock({
		productId: id,
		variantIds: variants?.map((variant) => variant.id),
	});

	const [selectedReviewGrade, setSelectedReviewGrade] = useState<
		number | undefined
	>(undefined);
	const [reviewsSortOption, setReviewsSortOption] = useState<string>();
	const {
		hasNextPage,
		isLoading: isLoadingInitialReviews,
		isLoadingMore: isLoadingMoreReviews,
		items: reviews,
		loadMore: loadMoreReviews,
		questionsSummary,
	} = useReviews(product.id, selectedReviewGrade, reviewsSortOption);

	const {
		reviewImages: topScoreReviewImages,
		selectedReviewImageId: selectedTopScoreReviewImageId,
		setSelectedReviewImageId: setSelectedTopScoreReviewImageId,
	} = useReviewImages({ productId: product.id, reviewScore: 5 });
	const {
		reviewImages: allReviewImages,
		selectedReviewImageId: selectedAllReviewImageId,
		setSelectedReviewImageId: setSelectedAllReviewImageId,
	} = useReviewImages({ productId: product.id });

	const { getStoredValue } = useTemporaryStoredValue('GTM_LIST_DATA');

	const parentItemListData = useRef<{
		gtmItemListId: GTMItemListId;
		gtmItemListName: GTMItemListName;
	}>();

	useEffect(() => {
		const stored = getStoredValue();
		if (stored) {
			parentItemListData.current = stored;
		}
		pushToGTM({
			type: 'view_item',
			payload: {
				product,
				category: product.category1Name,
				itemListId: parentItemListData?.current?.gtmItemListId,
				itemListName: parentItemListData?.current?.gtmItemListName,
			},
		});
		product.campaigns?.forEach((campaign) => {
			pushToGTM({
				type: 'view_item_list',
				payload: {
					itemListId: 'pdp_mix_match',
					itemListName: `Mix and Match: [${campaign.discountType}] - [${campaign.title}] ([${campaign.id}])`,
					products: [product],
					pageSize: 1,
				},
			});
		});
		// The product object could in theory change while the ID stays the same,
		// in which case additional events would not be desired.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [product.id]);

	useValueChangeEffect(isLoadingInitialSimilarProducts, (prevIsLoading) => {
		if (
			prevIsLoading &&
			!isLoadingInitialSimilarProducts &&
			is.arrayWithLength(similarProducts)
		) {
			pushToGTM({
				type: 'view_item_list',
				payload: {
					itemListId: 'pdp_similar_products',
					itemListName: `List: Similar products ${product.id}`,
					products: similarProducts,
					pageSize: similarProducts.length,
				},
			});
		}
	});

	const wishlistButtonState = useSelector(
		wishlistService,
		selectWishlistButtonState,
	);
	const wishlistAdditionalSalesProducts = useSelector(
		wishlistService,
		selectWishlistAdditionalSalesProducts,
	);
	const wishlistAddedProduct = useSelector(
		wishlistService,
		selectWishlistAddedProduct,
	);
	const wishlistMiniMessage = useSelector(
		wishlistService,
		selectWishlistMiniMessage,
	);
	const wishlistAddedProductPopoverIsOpen = useSelector(
		wishlistService,
		selectWishlistAddedProductPopoverIsOpen,
	);

	const purchaseButtonState = useSelector(
		cartService,
		selectPurchaseButtonState,
	);

	const { onlineCommerceEnabled } = useFeatureToggle();

	const {
		gtmItemListId: wishlistAdditionalSalesGTMItemListId,
		gtmItemListName: wishlistAdditionalSalesGTMItemListName,
		sendAddToWishlistEvent: sendWishlistAdditionalSalesAddEvent,
	} = useProductListGTMEvents(
		'pdp_add_to_wishlist_upsale',
		`List: Add to wishlist upsale ${id}`,
	);
	const { sendAddToCartEvent } = useProductGTMEvents();

	const {
		addPrintPlacement,
		addPrintPlacementButtonState,
		addPrintPlacementErrors,
		customizationId,
		existingPrintPlacements,
		hasError: productPrintHasError,
		isInitialLoading: isInitialLoadingProductPrint,
		placements,
		print,
		printPlacements,
		printUploadButtonState,
		printUploadErrors,
		removePrint,
		removePrintPlacement,
		reusePrint,
		storedPrints,
		totalCost,
		uploadPrint,
	} = useProductPrint({
		id,
		isActive: isCustomizable && salesAttributes?.isSellable !== false,
	});

	return (
		<ProductDetailsContext productId={product.id}>
			<ProductDetailsBlock
				product={product}
				key={id}
				onReviewGradeResetClick={() => {
					setSelectedReviewGrade(undefined);
				}}
				onReviewGradeClick={(score) => {
					setSelectedReviewGrade(score);
				}}
				isLoadingMoreReviews={Boolean(isLoadingMoreReviews)}
				onLoadMoreReviewsClick={() => {
					ignorePromiseRejection(loadMoreReviews());
				}}
				hasMoreReviews={Boolean(hasNextPage)}
				reviews={reviews}
				topScoreReviewImages={topScoreReviewImages}
				selectedTopScoreReviewImageId={selectedTopScoreReviewImageId}
				onTopScoreReviewImageClick={(imageId) => {
					setSelectedTopScoreReviewImageId(imageId);
				}}
				onSelectedReviewImagesSlideChange={(slideIndex) => {
					setSelectedTopScoreReviewImageId(
						topScoreReviewImages[slideIndex]?.id,
					);
				}}
				allReviewImages={allReviewImages}
				selectedAllReviewImageId={selectedAllReviewImageId}
				onAllReviewsImageClick={(imageId) => {
					setSelectedAllReviewImageId(imageId);
				}}
				onAllReviewImagesSlideChange={(slideIndex) => {
					setSelectedAllReviewImageId(allReviewImages[slideIndex]?.id);
				}}
				questionsSummary={questionsSummary}
				isReviewsOpen={isReviewsOpen}
				isTechnicalAttributesOpen={isTechnicalAttributesOpen}
				isImportantInfoOpen={isImportantInfoOpen}
				onImportantInfoClick={() => {
					setIsImportantInfoOpen((current) => !current);
				}}
				isAboutBrandOpen={isAboutBrandOpen}
				onAboutBrandClick={() => setIsAboutBrandOpen((current) => !current)}
				isSparePartsOpen={isSparePartsOpen}
				onSparePartsClick={() => setIsSparePartsOpen((current) => !current)}
				isBelongsToOpen={isBelongsToOpen}
				onBelongsToClick={() => setIsBelongsToOpen((current) => !current)}
				creditSimulationPopoverIsOpen={creditSimulationPopoverIsOpen}
				onCreditSimulationPopoverClose={() =>
					setCreditSimulationPopoverIsOpen(false)
				}
				onTechnicalAttributesClick={() => {
					setIsTechnicalAttributesOpen((current) => !current);
				}}
				onReviewsClick={() => {
					setIsReviewsOpen((current) => !current);
				}}
				onReviewsLinkClick={() => {
					setIsReviewsOpen(true);
				}}
				fit3Summary={questionsSummary?.find(({ name }) => name === 'fit3')}
				isLoadingFit={isLoadingInitialReviews}
				isLoadingInitialReviews={isLoadingInitialReviews}
				allStoresStock={allStoresStock}
				currentProductStock={currentProductStock}
				onCampaignPopoverProductAddToCartClick={(variant, campaign) => {
					const itemListId: GTMItemListId = 'pdp_mix_match';
					const itemListName: GTMItemListName = `Mix and Match: [${campaign.discountType}] - [${campaign.title}] ([${campaign.id}])`;

					cartService.send({
						type: 'ADD_ONE_FROM_BUY_BUTTON',
						variantId: variant.id,
						buttonId: `campaign-popover-${variant.id}`,
						metaData: {
							gtmItemListId: itemListId,
							gtmItemListName: itemListName,
						},
					});
					sendAddToCartEvent({
						product: variant,
						quantity: 1,
						itemListId,
						itemListName,
					});
				}}
				onCampaignPackageAddToCartClick={
					onlineCommerceEnabled
						? (campaign: CampaignResponse) => {
								cartService.send({
									type: 'ADD_MULTIPLE_VARIANTS_MINI',
									variants: campaign.variants.map((variant) => ({
										variantId: variant.id,
										quantity: variant.quantity,
									})),
									buttonId: `addCampaignToCart-${campaign.title}-${campaign.validTo}`,
								});
							}
						: undefined
				}
				currentStoreStock={currentStoreStock}
				isLoadingInitialStock={isInitialLoadingProductStock || !isStockActive}
				isLoadingAllStoresStock={isLoadingAllStoresStock}
				isLoadingNearbyStoresStock={isLoadingNearbyStoresStock}
				purchaseButtonState={purchaseButtonState}
				onMainPurchaseButtonClick={() => {
					cartService.send({
						type: 'ADD_ONE_FROM_BUY_BUTTON',
						variantId: product.id,
						customizationId,
						openAddedProductPopover: true,
						metaData: parentItemListData?.current
							? {
									gtmItemListId: parentItemListData?.current?.gtmItemListId,
									gtmItemListName: parentItemListData?.current?.gtmItemListName,
								}
							: undefined,
					});
					sendAddToCartEvent({
						product,
						quantity: 1,
						itemListId: parentItemListData?.current?.gtmItemListId,
						itemListName: parentItemListData?.current?.gtmItemListName,
					});
					if (customizationId) {
						pushToGTM({
							type: 'profiling_add',
							payload: {
								productId: product.id,
							},
						});
					}
				}}
				wishlistButtonState={wishlistButtonState}
				onMainWishlistButtonClick={() => {
					wishlistService.send({
						type: 'ADD_ONE_TO_WISHLIST',
						variantId: product.id,
						showToast: true,
						openAddedProductPopover: true,
						GTMData: {
							type: 'add_to_wishlist',
							payload: { product, quantity: 1 },
						},
					});
					sendGlobalEvent('engagement', { type: 'addArticleToWishlist' });
				}}
				onStockInformationOpen={() => {
					if (selectedStore) {
						loadNearbyStores();
						loadAllStores();
					} else {
						loadAllStores();
					}
					pushToGTM({ type: 'select_store_open' });
				}}
				onSizeGuideClick={() => {
					setIsSizeGuidePopoverOpen(true);
				}}
				onVariantPickerOpen={() => {
					loadVariantsStock();
				}}
				onCreditSimulationClick={() => {
					setCreditSimulationPopoverIsOpen(true);
					pushToGTM({ type: 'open_credit_simulation' });
				}}
				variantsStock={variantsStock}
				selectedStore={selectedStore}
				isLoadingVariantsStock={isLoadingVariantsStock}
				isPurchasable={Boolean(
					salesAttributes?.isSellable &&
						// we want to default to an enabled purchase button if we don't have stock information
						(currentProductStock?.webStock?.inStock ?? true),
				)}
				onUpdateSelectedStore={(store) => {
					if (store.id && store.name) {
						setSelectedStore({ id: store.id, name: store.name });
					}
				}}
				nearbyStoresStock={nearbyStoresStock}
				isLoadingProductStock={isLoadingProductStock}
				reviewPolicyUrl={reviewPolicy}
				onWishlistAddedProductPopoverClose={() => {
					wishlistService.send({ type: 'RESET_ADDED_PRODUCT_POPOVER' });
				}}
				wishlistAdditionalSalesGTMItemListId={
					wishlistAdditionalSalesGTMItemListId
				}
				wishlistAdditionalSalesGTMItemListName={
					wishlistAdditionalSalesGTMItemListName
				}
				wishlistAddedProductPopoverIsOpen={wishlistAddedProductPopoverIsOpen}
				onWishlistAdditionalSalesButtonClick={(additionalSalesProduct) => {
					wishlistService.send({
						type: 'ADD_ONE_TO_WISHLIST',
						variantId: additionalSalesProduct.id,
						openAddedProductPopover: false,
						showToast: false,
					});
					sendWishlistAdditionalSalesAddEvent(additionalSalesProduct, 1);
					sendGlobalEvent('engagement', { type: 'addArticleToWishlist' });
				}}
				wishlistAddedProduct={wishlistAddedProduct}
				wishlistAdditionalSalesProducts={wishlistAdditionalSalesProducts}
				wishlistAddedProductPopoverMessage={wishlistMiniMessage}
				belongsToProducts={belongsToProducts}
				belongsToIsLoadingMore={Boolean(belongsToIsLoadingMore)}
				belongsToIsLoading={belongsToIsLoading}
				belongsToHasNextPage={belongsToHasNextPage}
				onBelongsToLoadMoreClick={() =>
					ignorePromiseRejection(loadMoreBelongsTo())
				}
				sparePartsProducts={sparePartsProducts}
				sparePartsIsLoadingMore={Boolean(sparePartsIsLoadingMore)}
				sparePartsIsLoading={sparePartsIsLoading}
				sparePartsHasNextPage={sparePartsHasNextPage}
				onSparePartsLoadMoreClick={() =>
					ignorePromiseRejection(loadMoreSpareParts())
				}
				accessoriesProducts={accessoriesProducts}
				accessoriesIsLoadingMore={Boolean(accessoriesIsLoadingMore)}
				accessoriesIsLoading={accessoriesIsLoading}
				accessoriesHasNextPage={accessoriesHasNextPage}
				onAccessoriesLoadMoreClick={() =>
					ignorePromiseRejection(loadMoreAccessories())
				}
				similarProducts={similarProducts}
				isLoadingInitialSimilarProducts={isLoadingInitialSimilarProducts}
				productQuestions={productQuestions}
				productQuestionsHasNextPage={productQuestionsHasNextPage}
				productQuestionsIsLoading={isLoadingInitialQuestions}
				productQuestionsIsLoadingMore={Boolean(isLoadingMoreQuestions)}
				onProductQuestionsLoadMoreClick={() =>
					ignorePromiseRejection(loadMoreProductQuestions())
				}
				isProductQuestionsOpen={isProductQuestionsOpen}
				onProductQuestionsClick={() =>
					setIsProductQuestionsOpen((current) => !current)
				}
				productQuestionsNewQuestionsAllowed={Boolean(newQuestionsAllowed)}
				reviewsSortOption={reviewsSortOption}
				onReviewsSortOptionChange={(option) => setReviewsSortOption(option)}
				isInitialLoadingProductPrint={isInitialLoadingProductPrint}
				productPrintHasError={productPrintHasError}
				addPrintPlacementButtonState={addPrintPlacementButtonState}
				addPrintPlacementErrors={addPrintPlacementErrors}
				existingPrintPlacements={existingPrintPlacements}
				onAddPrintPlacementClick={addPrintPlacement}
				onRemovePrintPlacementClick={removePrintPlacement}
				onReusePrintClick={reusePrint}
				placements={placements}
				printPlacements={printPlacements}
				storedPrints={storedPrints}
				print={print}
				fileUploadButtonState={printUploadButtonState}
				onUpLoadPrint={(file) => {
					ignorePromiseRejection(uploadPrint(file));
				}}
				printUploadErrors={printUploadErrors}
				productPrintTotalCost={totalCost}
				onRemovePrintClick={removePrint}
			/>
			{sitecoreContext.metaData?.structuredData && (
				<Script
					id={`application/ld+json${product.id}`}
					type="application/ld+json"
					dangerouslySetInnerHTML={{
						__html: JSON.stringify(sitecoreContext.metaData?.structuredData),
					}}
				/>
			)}
			<PartialPopover
				heading={t('product_details_sizeguide_heading')}
				isOpen={isSizeGuidePopoverOpen}
				item={`${sizeGuideUrl}&productId=${product.productId}`}
				onClose={() => setIsSizeGuidePopoverOpen(false)}
			/>
		</ProductDetailsContext>
	);
}
ProductDetails.displayName = 'ProductDetails';

export default function ProductDetailsInstance(props: Props) {
	if (!props?.fields) {
		return null;
	}

	return (
		<ErrorBoundary isPageWidth>
			<ProductDetails {...props} key={props.fields.id} />
		</ErrorBoundary>
	);
}
ProductDetailsInstance.displayName = 'ProductDetailsInstance';
